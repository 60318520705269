<template>
  <div class="information getforMessage">
    <!-- 头部 -->
    <div class="information-haeder-box">
      <div class="information-haeder">
        <div>
          <div class="quan" @click="goback">
            <img src="../../assets/img/views/jiantou.png" alt />
          </div>
          <span class="xiangq">{{ $route.meta.title }}</span>
        </div>
        <div>
          <el-button
            type="primary"
            class="haeder-fuzhi"
            size="small"
            @click="copy(textData)"
            >一键复制</el-button
          >
        </div>
      </div>
    </div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="  " class="breadcrumb">
      <el-breadcrumb-item style="cursor: pointer">
        <div @click="goback">话题分析</div>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span class="breadcrumb-meaasge">/</span> 信息详情
      </el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 外面的大盒子 -->
    <div v-loading="loading" class="information-box">
      <div class="informaton-main">
        <div class="main-top">
          <div class="main-top-left"></div>
          <!-- 中间部分 -->
          <div class="main-top-center">
            <p
              v-html="textData.title ? textData.title : ''"
              class="center-title"
            ></p>
            <div class="caozuo-center">
              <!-- <div
                :class="['type',textData.tonalState == 2 ? 'negative' : (textData.tonalState == 1 ? 'neutral' : 'obverse')]"
              >{{textData.tonalState == 2 ? '负面': textData.tonalState == 0 ? '正面' : '中性'}}</div>-->
              <div v-if="textData.mediaLink != null" class="type website">
                {{ textData.mediaLink }}
              </div>
              <span class="original" @click="lookOriginal(textData.website)">
                <span>
                  <img src="../../assets/img/views/wenzhang.png" alt />
                </span>
                <span>查看原文</span>
              </span>
              <div class="center-timer">
                发布时间:
                <span>{{
                  textData.releaseTime ? textData.releaseTime : "无"
                }}</span>
              </div>
            </div>
            <div class="center-bottom">
              <span class="center-meiti">
                作者ID：
                <span>{{ textData.authorId ? textData.authorId : "无" }}</span>
                <span style="width: 25px; height: 20px">
                  <span
                    class="img my-caozuo"
                    @click="myCopy([textData.authorId], 5)"
                  >
                    <img src="../../assets/img/views/hfz.png" alt />
                  </span>
                </span>
              </span>
              <!-- <span class="center-meiti">
                媒体性质：
                <span>{{
                  (textData.mediaLink ? textData.mediaLink : "其它") +
                  "/" +
                  (textData.mediaAttribute ? textData.mediaAttribute : "其它") +
                  "/" +
                  (textData.mediaLevel ? textData.mediaLevel : "其它")
                }}</span>
                <span style="width: 25px; height: 20px">
                  <span
                    class="img my-caozuo"
                    @click="
                      myCopy(
                        [
                          textData.mediaLink,
                          textData.mediaAttribute,
                          textData.mediaLevel,
                        ],
                        1
                      )
                    "
                  >
                    <img src="../../assets/img/views/hfz.png" alt />
                  </span>
                </span>
              </span> -->
              <span class="center-source" style="margin-left: 40px">
                来源/作者：
                <span>{{
                  (textData.source ? textData.source : "无") +
                  "/" +
                  (textData.author ? textData.author : "无")
                }}</span>
                <span style="width: 25px; height: 20px">
                  <span
                    class="img my-caozuo"
                    @click="myCopy([textData.source, textData.author], 2)"
                  >
                    <img src="../../assets/img/views/hfz.png" alt />
                  </span>
                </span>
              </span>
              <div
                style="color: #888; font-size: 14px; margin-right: 40px"
                v-if="isPublic"
              >
                媒体观点：{{
                  textData.mediaViewId
                    ? cities2
                        .filter((x) => {
                          if (textData.mediaViewId.indexOf(x.mediaViewId) != -1)
                            return true;
                        })
                        .map((x) => {
                          return x.viewName;
                        })
                        .join("/")
                    : ""
                }}
              </div>
              <div
                style="
                  color: #888;
                  font-size: 14px;
                  height: 35px;
                  margin-right: 40px;
                "
                v-else
              >
                媒体观点:
                <el-select
                  @change="changeViewId(textData)"
                  v-model="textData.mediaViewId"
                  multiple
                  collapse-tags
                  style="margin-left: 10px; width: 320px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="ite in cities2"
                    :key="ite.mediaViewId"
                    :label="ite.viewName"
                    :value="ite.mediaViewId"
                  ></el-option>
                </el-select>
              </div>
              <div
                class="settingSample"
                @click="settingSample(textData)"
                v-if="!isPublic"
              >
                <span
                  class="settingSample-title"
                  v-if="textData.isSetSample == 0"
                >
                  <img src="../../assets/img/views/yangben.png" alt />
                  <span style="margin-left: 8px">设为样本</span>
                </span>
                <span class="settingSample-title" v-else style="color: blue">
                  <img src="../../assets/img/views/blue.png" alt />
                  <span style="margin-left: 8px">已设为样本</span>
                </span>
              </div>
              <!-- <span v-if="textData.eventClass" class="center-fenlei">
								事件分类:
								<span>{{textData.eventList[0]}}</span>
								<el-tooltip v-if="textData.eventClass&&textData.eventList.length>=2" class="item"
									effect="dark" placement="top">
									<div slot="content" v-html="textData.eventClass"></div>
									<span class="gengduo">(更多)</span>
								</el-tooltip>
								<span style="width:25px;height:20px">
									<span class="img my-caozuo" @click="myCopy(textData.eventClass,3)">
										<img src="../../assets/img/views/hfz.png" alt />
									</span>
								</span>
							</span>
							<span class="center-fenlei" v-else>
								事件分类: 无
								<span style="width:25px;height:20px">
									<span class="img my-caozuo" @click="myCopy(textData.eventClass,3)">
										<img src="../../assets/img/views/hfz.png" alt />
									</span>
								</span>
              </span>-->
            </div>
            <!-- <div class="center-key-box">
							<div class="center-key">
								关键字:
								<div v-if="textData.keywordList">
									<span v-for="(ite,indx) in textData.keywordList"
										:class="[ite != textData.keywordList[textData.keywordList.length-1]? 'styleMargin': '']"
										:key="indx">{{ite}}</span>
								</div>
								<span v-else>无</span>
							</div>
							<div v-if="textData.keyword&&textData.keywordArry.length>10" class="gengduo-link-box">
								<el-tooltip class="item" effect="dark" placement="top">
									<div slot="content" v-html="textData.keyword"></div>
									<span class="gengduo-link">(更多)</span>
								</el-tooltip>
							</div>
							<span style="width:25px;height:20px;margin-left:5px;margin-top:3px">
								<span class="img my-caozuo" @click="myCopy(textData.keyword,4)">
									<img src="../../assets/img/views/hfz.png" alt />
								</span>
							</span>
            </div>-->
          </div>
          <div class="main-top-right"></div>
        </div>
        <div style="padding: 32px 210px 0 210px; min-height: 430px">
          <p
            v-html="item ? item : ''"
            class="main-text"
            v-for="(item, index) in textData.contentList"
            :key="index"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// getInfoMsg
import { getInfoKeMsg, settingLook, mediaList } from "../../api/topic/topic.js";
export default {
  data() {
    return {
      loading: true,
      dataId: "",
      topicId: "",
      id: "",
      type: "",
      topicType: "",
      viewId: "", //点击进详情时传过来的观点id
      textData: {},
      keyList: [],
      //   观点列表
      cities2: [],
      isPubilc: false, // 是否是公共话题中转来的
    };
  },
  methods: {
    goback() {
      this.$router.push({
        path: "/topics/index/topicList/topicListDetails",
        query: {
          topicId: this.topicId,
          id: this.id,
          type: this.type,
          topicType: this.topicType,
        },
      });
    },
    // 设为样本
    async settingSample(item) {
      item.isSetSample = !item.isSetSample;
      const res = await settingLook([
        {
          topicId: item.topicId,
          topicMsgId: String(item.topicMsgId),
          isSetSample: item.isSetSample ? 1 : 0,
          isView: 1,
          mediaViewId: item.mediaViewId ? item.mediaViewId.join(",") : "",
        },
      ]);
      if (res.data.code == 200) {
        this.$message.success("修改成功！");
      } else {
        this.$message.error("修改失败！");
      }
    },
    // 媒体观点列表
    async mediaList() {
      const res = await mediaList({
        topicId: this.id,
      });
      if (res.data.rows) {
        this.cities2 = res.data.rows;
      }
    },
    // 下拉框设置观点
    async changeViewId(val) {
      let mediaViewList = [
        {
          topicMsgId: String(val.topicMsgId),
          isView: val.mediaViewId.length == 0 ? 0 : 1,
          mediaViewId: val.mediaViewId.join(","),
        },
      ];
      const res = await settingLook(mediaViewList);
      if (res.data.code == 200) {
        this.$message.success("操作成功！");
      } else {
        this.$message.error("操作失败！");
      }
    },
    // 一键复制
    copy(item) {
      this.$publicFun.copyFun(item.copys);
    },
    // 个别复制
    myCopy(msg, type) {
      let str = "";
      if (type == 1) {
        msg = msg.map((item) => {
          return item ? item : "其它";
        });
        str = `媒体性质:${msg}`;
      }
      if (type == 2) {
        str = `来源/作者:${msg}`;
      }
      msg = msg ? msg : "无";
      if (type == 3) {
        str = `事件分类:${msg}`;
      } else if (type == 4) {
        str = `关键字:${msg}`;
      }
      if (type == 5) {
        str = `作者ID:${msg}`;
      }
      this.$publicFun.copyFun(str);
    },
    lookOriginal(web) {
      window.open(web);
    },
    async getMsginfo() {
      const res = await getInfoKeMsg({
        topicMsgId: String(this.dataId),
        topicId: this.id,
      });
      if (res.data.data) {
        this.textData = res.data.data;
        this.textData.mediaViewId = this.viewId;
      }
      if (this.textData.eventClass) {
        this.textData.eventList = this.textData.eventClass.split(" ");
        let reg = new RegExp(" ", "g"); //g代表全部
        this.textData.eventClass = this.textData.eventClass.replace(
          reg,
          "<br/>"
        );
      } else {
        this.textData.eventList = [];
      }
      if (this.textData.keyword) {
        this.textData.keywordList = this.textData.keyword.split(" ");
        this.textData.keywordArry = this.textData.keyword.split(" ");
        if (this.textData.keywordList.length > 10) {
          this.textData.keywordList = this.textData.keywordList.slice(0, 10);
        }
        let reg = new RegExp(" ", "g"); //g代表全部
        this.textData.keyword = this.textData.keyword.replace(reg, "<br/>");
      }
      if (this.textData.content) {
        this.textData.contentList = this.textData.content.split("\n");
      }
      // this.heightText();
      // wei匹配到的观点id
      let arrList = [];
      let idList = [];
      this.cities2.forEach((ite) => {
        //拿到观点列表
        idList.push(ite.mediaViewId);
      });
      // 将数据和观点ID进行比较  拿出不匹配的
      if (this.textData.mediaViewId) {
        this.textData.mediaViewId.forEach((ite) => {
          if (idList.indexOf(ite) == -1) {
            arrList.push(ite);
          }
        });
      }
      // 将未匹配到的观点id 和数组每一项比较 如果有就把对应的删除掉
      arrList.forEach((item) => {
        if (this.textData.mediaViewId) {
          this.textData.mediaViewId.forEach((it, index) => {
            if (item == it) {
              this.textData.mediaViewId.splice(index, 1);
            }
          });
        }
      });
      this.loading = false;
    },
    // heightText() {
    //   if (this.textData.keyword) {
    //     let word = this.textData.keyword.split("<br/>");
    //     if (word[word.length - 1] == "") {
    //       word.pop();
    //     }
    //     word.forEach(ite => {
    //       let reg = new RegExp(ite, "g"); //g代表全部
    //       if (this.textData.title) {
    //         this.textData.title = this.textData.title.replace(
    //           reg,
    //           '<span style="color:red">' + ite + "</span>"
    //         );
    //       }
    //       if (this.textData.contentList) {
    //         for (let i = 0; i < this.textData.contentList.length; i++) {
    //           this.textData.contentList[i] = this.textData.contentList[
    //             i
    //           ].replace(reg, '<span style="color:red">' + ite + "</span>");
    //         }
    //       }
    //     });
    //   }
    // }
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.dataId = params.topicMsgId;
      this.topicId = params.topicId;
      this.id = params.id;
      this.type = params.type;
      this.topicType = params.topicType;
      this.viewId = params.viewId;
      this.isPublic = params.pubuic == 1 ? true : false;
    }
    this.viewId = this.viewId.split(",");
    this.viewId = this.viewId.map((item) => {
      return Number(item);
    });
    this.mediaList();
    setTimeout(() => {
      this.getMsginfo();
    }, 500);
  },
};
</script>

<style scoped>
.information-haeder {
  justify-content: space-between;
  display: flex;
  padding: 10px 24px;
  width: 100%;
  height: 56px;
  background: #ffffff;
}

.breadcrumb {
  margin-top: 77px;
  margin-left: 24px;
}

.quan {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #cccccc;
  border-radius: 50%;
}

.gengduo-link {
  font-size: 14px;
  cursor: pointer;
  color: #2e59ec;
  margin-left: 5px;
}

.xiangq {
  margin-top: 4px;
  margin-left: 16px;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.styleMargin {
  margin-right: 5px;
}

.information-box {
  padding: 0 24px 24px 24px;
}

.informaton-main {
  padding: 35px 32px 28px 35px;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
}

.main-top {
  position: relative;
  padding: 40px 210px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e4e6ec;
}

.main-top-left {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 37px;
  height: 55px;
  background: url("../../assets/img/views/beijing.png");
  background-size: 37px 55px;
}

.main-top-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 166px;
  height: 180px;
  background: url("../../assets/img/views/beijing.png");
}

.center-title {
  max-width: 1130px;
  font-size: 30px;
  font-weight: bold;
  color: #333333;
  word-wrap: break-word;
}

.type {
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  padding: 0 8px;
  height: 24px;
}

.website {
  background-color: #868bff;
}

.original {
  display: flex;
  margin-left: 16px;
  cursor: pointer;
  font-size: 14px;
  color: #2e59ec;
}

.original span:nth-child(1) {
  margin-top: 2px;
  margin-right: 5px;
}

.center-timer {
  display: flex;
  justify-content: space-between;
  margin-left: 24px;
  font-size: 14px;
  color: #999999;
}

.center-meiti,
.center-fenlei {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #999999;
}
.center-source {
  position: relative;
  height: 35px;
  display: flex;
  font-size: 14px;
  color: #999999;
  align-items: center;
  margin-right: 40px;
  line-height: 35px;
}
.center-fenlei {
  margin-left: 40px;
}

.center-meiti .img,
.center-fenlei .img {
  position: absolute;
  right: 0;
  top: 7px;
  margin: 0;
}
.center-source .img {
  position: absolute;
  right: 0;
  top: 3px;
  margin: 0;
}
.center-bottom {
  display: flex;
  align-items: center;
  height: 35px;
  margin-top: 16px;
}

.gengduo {
  padding-left: 8px;
  width: 46px;
  height: 20px;
  cursor: pointer;
  color: #2e59ec;
  font-size: 14px;
}

.center-key-box {
  display: flex;
  align-items: center;
}

.center-key {
  max-width: 760px;
  display: flex;
  font-size: 14px;
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.center-key span,
.center-key div {
  width: 100%;
}

.gengduo-link-box {
  position: relative;
}

.gengduo-link-box img {
  position: absolute;
  top: 16%;
  right: -62%;
}

.main-text {
  line-height: 26px;
  text-indent: 2em;
  font-size: 14px;
  color: #333333;
}

.caozuo-center {
  line-height: 24px;
  margin-top: 24px;
  display: flex;
}
.settingSample {
  font-size: 14px;
  color: #999999;
}
.settingSample-title {
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
